import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from './Layout';
import { ResourcesListing } from '~/components/flexible/Resources';
import { ResourcesLayout } from '~/components/landing/Resources';
import categoryMastheadMedia from '~/assets/images/category-masthead.png';
import { LayoutRenderer } from '~/components/layouts/LayoutRenderer';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';

import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function CategoryPage({
  data: { wpPage, wpPost, resourcesPage, wpCategory, wp },
}: {
  data: { wpCategory };
}) {
  const featured = [];

  return (
    <Layout headerType="newsletter" wp={wp} page={wpCategory}>
      <Seo post={wpCategory} title={wpCategory?.name} />
      <ResourcesLayout>
        <Masthead {...wpCategory} />
        {featured.length > 0 && (
          <ResourcesListing heading="Featured" items={featured} style="featured-two-col" category limit={100} />
        )}
        {wpCategory.slug === 'articles' > 0 && (
          <ResourcesListing items={wpCategory.posts?.nodes || []} category limit={100} />
        )}
        {['cfo-spotlight', 'financial-insights', 'metrics-that-matter', 'strategic-planning', 'company-news'].indexOf(
          wpCategory.slug
        ) > -1 && (
          <ResourcesListing items={wpCategory.posts?.nodes || []} category last style="three-col" limit={100} />
        )}
        {['cfo-spotlight', 'financial-insights', 'metrics-that-matter', 'strategic-planning', 'company-news'].indexOf(
          wpCategory.slug
        ) === -1 && (
          <ResourcesListing items={wpCategory.posts?.nodes || []} category last style="two-col" limit={100} />
        )}
      </ResourcesLayout>
      <LayoutRenderer
        prefix="Page_Posttypepage_Sections"
        sections={resourcesPage?.postTypePage?.sections?.filter(
          (section) => section?.fieldGroupName === 'Page_Posttypepage_Sections_ReusableBlock'
        )}
      />
    </Layout>
  );
}

export const Masthead = ({ name, categoryOptions }) => {
  if (!categoryOptions) return null;
  const { categoryMastheadImage } = categoryOptions;
  return (
    <div className="bg-fadedBlue  pt-8 md:pt-0 md:pl-[50px] md:pr-10 flex flex-col md:flex-row items-center space-y-8 md:space-y-0 overflow-hidden">
      <div className="space-y-2 text-center md:text-left md:py-14 ">
        <div className="font-semibold uppercase t-17">Category</div>
        <div className="t-36">{name}</div>
      </div>
      <div className="md:bg-transparent md:w-[445px] md:ml-auto">
        <MediaAssetInner
          className="mb-4 mx-auto md:mx-0 w-[60%] md:w-auto md:mb-[-30%] md:mt-[-21%] md:pr-12"
          type="image"
          image={categoryMastheadImage}
        />
      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query Category($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    resourcesPage: wpPage(id: { eq: "cG9zdDozMzU=" }) {
      ...SEO
      ...GeneratedWpPage
    }
    wpPost(id: { eq: $id }) {
      ...GeneratedWpPost
    }
    wpCategory(id: { eq: $id }) {
      link
      name
      slug
      categoryOptions {
        categoryMastheadImage {
          ...Image
        }
      }
      posts {
        nodes {
          link
          title
          categories {
            nodes {
              slug
              name
            }
          }
          postImported {
            postSummary
          }
          postTypePost {
            readLabel
            sidebarDescription
            previewImage {
              ...Image
            }
            previewSize
            previewBackground
            externalLink {
              title
              url
            }
          }
        }
      }
    }
    wp {
      ...GeneratedWp
    }
  }
`;
